export enum PaymentPlanStatus {
  Paid = 'paid',
  Current = 'current',
  Past_due = 'pastdue',
  Suspended = 'suspended',
  Deactivated = 'deactivated',
  Default = 'default',
  Missed = 'missed',
  Scheduled = 'scheduled',
}

export type PaymentPlanStatusLabel =
  | 'Paid'
  | 'Current'
  | 'Past Due'
  | 'Suspended'
  | 'Deactivated'
  | 'Missed'
  | ''
  | 'Scheduled';

export const PaymentPlanStatusLabels: Record<
  PaymentPlanStatus,
  PaymentPlanStatusLabel
> = {
  [PaymentPlanStatus.Paid]: 'Paid',
  [PaymentPlanStatus.Current]: 'Current',
  [PaymentPlanStatus.Past_due]: 'Past Due',
  [PaymentPlanStatus.Suspended]: 'Suspended',
  [PaymentPlanStatus.Deactivated]: 'Deactivated',
  [PaymentPlanStatus.Missed]: 'Missed',
  [PaymentPlanStatus.Default]: '',
  [PaymentPlanStatus.Scheduled]: 'Scheduled',
};
