import React, { useEffect, useState, useMemo } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { getStates, getUserProfile, updateUserProfile } from 'src/api';
import { ZIP_CODE_REGEX } from 'src/constants/regex';
import { dateFormat } from 'src/helpers/format';
import { NunitoSansErrorMessage, NunitoSansLink, Subtitle2 } from 'src/styles';
import QueryKeys from 'src/types/query-keys';
import { Profile } from 'src/types/user';
import { mobileNumberFormatter } from 'src/helpers/format';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Divider, Grid, styled, useTheme, Snackbar } from '@mui/material';
import { Button, Alert } from '@pennfoster/pfc-design-system';
import FormDialogs from './form-dialogs';
import FormSkeleton from './form-skeleton';
import { State } from 'src/types/states';
import { FormInputText } from './form-input-text';
import { FormInputSelect } from './form-input-select';
import { getErrorMessage } from 'src/helpers/handling-errors';
import ErrorMessage from '../error-message';
import { AxiosError } from 'axios';
import { normalizeNumberInput } from 'src/helpers/normalize';

export const BoxStyles = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2.5),
  marginBottom: theme.spacing(4),
  backgroundColor: theme.palette.common.white,
}));

export interface IFormInput {
  firstName: string;
  lastName: string;
  emailAddress: string;
  phoneNumber: string;
  dateOfBirth: string;
  preferredName: string;
  address: {
    address1: string;
    address2: string;
    city: string;
    regionAbbr: string;
    postalCode: string;
    country: string;
  };
}

const schema = yup.object().shape({
  address: yup.object().shape({
    postalCode: yup
      .string()
      .max(5, 'Zip Code must be less than 5 characters')
      .matches(ZIP_CODE_REGEX, 'invalid zip code')
      .nullable()
      .transform((value) => (value ? value : null)),
  }),
  phoneNumber: yup
    .string()
    .nullable()
    .transform((value) => (value ? value : null)),
});

export default function EditProfileForm() {
  const theme = useTheme();
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [cancelDialogOpen, setCancelDialogOpen] = useState<boolean>(false);
  const [isEditingProfile, setIsEditingProfile] = useState<boolean>(false);
  const [isEditingAddress, setIsEditingAddress] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [successSnackbarOpen, setSuccessSnackbarOpen] = useState<boolean>(false);
  const [phoneNumberFlags, setPhoneNumberFlags] = useState({
    isValidPhoneNumber: false,
    isFocusPhoneNumber: false,
    isErrorPhoneNumber: false,
  });
  const [errorsFromAPI, setErrorsFromAPI] = useState<string>('');
  const [, setValidationError] = useState<string>('');
  const profileQuery = useQuery(QueryKeys.GET_PROFILE, getUserProfile);
  const profileData = profileQuery.data?.data;
  const statesQuery = useQuery(QueryKeys.GET_STATES, getStates);
  const statesData = statesQuery.data?.data || [];
  const minLengthPhoneNumber = 10;

  const mutation = useMutation({
    mutationFn: (data: Profile) => updateUserProfile(data),
    onSuccess: () => {
      profileQuery.refetch();
      setDialogOpen(false);
      setIsLoading(false);
      setIsEditingAddress(false);
      setIsEditingProfile(false);
      setErrorsFromAPI('');
      setSuccessSnackbarOpen(true);
    },
    // When validation task is done will add errors to form
    onError: (error: AxiosError) => {
      const errorMessage = getErrorMessage(error);
      setErrorsFromAPI(errorMessage);
      setDialogOpen(false);
      setIsLoading(false);
    },
  });

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore

  const handleCloseSuccessSnackbar = () => {
    setSuccessSnackbarOpen(false);
  };

  function toggleEditingProfile() {
    setIsEditingProfile((prevState) => !prevState);
  }

  function toggleEditingAddress() {
    setIsEditingAddress((prevState) => !prevState);
  }

  function handleCancel() {
    reset(getFormValues(profileData, statesData));
    setCancelDialogOpen(false);
    setIsEditingAddress(false);
    setIsEditingProfile(false);
    setErrorsFromAPI('');
  }

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    event.preventDefault();
    const { value } = event.target;
    event.target.value = normalizeNumberInput(value);
  }

  function getFormValues(data: Profile | undefined, states: State[]) {
    if (data && states) {
      return {
        firstName: data?.firstName,
        lastName: data?.lastName,
        emailAddress: data?.emailAddress,
        phoneNumber: mobileNumberFormatter(data?.phoneNumber),
        dateOfBirth: dateFormat(data?.dateOfBirth.toString(), {
          month: 'numeric',
          day: 'numeric',
          year: 'numeric',
        }),
        preferredName: data?.preferredName || '',
        address: {
          address1: data?.address?.address1 || '',
          address2: data?.address?.address2 || '',
          city: data?.address.city || '',
          regionAbbr: data?.address?.regionAbbr,
          postalCode: data?.address.postalCode || '',
          country: data?.address.country,
        },
      };
    }
    return {};
  }
  const handleClickSave = () => {
    handleSubmit((data) => {
      if (data.phoneNumber && data.phoneNumber.length < minLengthPhoneNumber) {
        setPhoneNumberFlags({
          ...phoneNumberFlags,
          isErrorPhoneNumber: true,
        });
        setError('phoneNumber', {
          type: 'manual',
          message: 'Invalid Phone Number',
        });
        return;
      }
      setPhoneNumberFlags({
        ...phoneNumberFlags,
        isErrorPhoneNumber: false,
      });
      setDialogOpen(true);
    })();
  };

  const onSubmit: SubmitHandler<IFormInput> = (data) => {
    setIsLoading(true);
    const dateOfBirth = new Date(data.dateOfBirth).toISOString();
    mutation.mutate({
      ...data,
      dateOfBirth,
    });
  };

  const {
    register,
    getValues,
    setValue,
    watch,
    reset,
    handleSubmit,
    setError,
    formState: { errors, isDirty },
  } = useForm<IFormInput>({
    shouldUnregister: false,
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: getFormValues(profileData, statesData),
  });

  const stateOptions = useMemo(() => {
    return statesData?.map((state: { code: string }) => ({
      label: state.code,
      value: state.code,
    }));
  }, [statesData, getValues('address.country')]);

  const hasErrors = useMemo(() => Object.keys(errors).length > 0, [Object.keys(errors).length]);

  useEffect(() => {
    reset(getFormValues(profileData, statesData));
  }, [profileData, statesData]);
  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      setValidationError('Please correct the errors below');
      setErrorsFromAPI('');
    } else {
      setValidationError('');
    }
  }, [errors]);

  const firstNameRegister = register('firstName');
  const lastNameRegister = register('lastName');
  const emailAddressRegister = register('emailAddress');
  const phoneNumberRegister = register('phoneNumber');
  const dateOfBirthRegister = register('dateOfBirth');
  const preferredNameRegister = register('preferredName');

  const address1Register = register('address.address1');
  const address2Register = register('address.address2');
  const cityRegister = register('address.city');
  const regionRegister = register('address.regionAbbr');
  const postalCodeRegister = register('address.postalCode');
  const countryRegister = register('address.country');

  const handlePhoneNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();

    const inputValue = event.target.value;
    const formattedNumber = mobileNumberFormatter(inputValue);
    setValue('phoneNumber', formattedNumber);

    if (inputValue.length > minLengthPhoneNumber) {
      setPhoneNumberFlags({
        ...phoneNumberFlags,
        isValidPhoneNumber: true,
        isErrorPhoneNumber: false,
      });
    } else {
      setPhoneNumberFlags({
        ...phoneNumberFlags,
        isValidPhoneNumber: false,
        isErrorPhoneNumber: false,
      });
    }
  };

  const handleFocusPhoneNumber = () => {
    setPhoneNumberFlags({
      ...phoneNumberFlags,
      isFocusPhoneNumber: true,
    });
  };

  const handleBlurPhoneNumber = () => {
    setPhoneNumberFlags({
      ...phoneNumberFlags,
      isFocusPhoneNumber: false,
    });
  };

  watch();

  return (
    <>
      <Box sx={{ flexGrow: 1, pb: 2 }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid item xs={12} sx={{ pb: 1 }}>
            <ErrorMessage errors={errors} />
          </Grid>
          {errorsFromAPI && (
            <Grid item xs={12} sx={{ pb: 1 }}>
              <ErrorMessage errors={errorsFromAPI} />
            </Grid>
          )}

          <BoxStyles role="section">
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                mb: theme.spacing(1),
              }}
            >
              <Subtitle2 variant="h2" sx={{ color: theme.palette.text.primary }}>
                Profile Info
              </Subtitle2>
              {!isEditingProfile && <Button label="Edit" onClick={toggleEditingProfile} pfVariant="text" />}
            </Box>
            <Box sx={{ mb: theme.spacing(2.5) }}>
              <Divider aria-hidden="true" />
            </Box>
            {profileData && statesData.length > 0 ? (
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <FormInputText
                    name="firstName"
                    placeholder="--"
                    label="First Name"
                    register={firstNameRegister}
                    getValues={getValues}
                    readOnly
                    className="not-clickable"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormInputText
                    name="lastName"
                    placeholder="--"
                    label="Last Name"
                    register={lastNameRegister}
                    getValues={getValues}
                    readOnly
                    className="not-clickable"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormInputText
                    name="emailAddress"
                    placeholder="--"
                    label="Email Address"
                    register={emailAddressRegister}
                    getValues={getValues}
                    readOnly
                    className="not-clickable"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormInputText
                    name="phoneNumber"
                    label="Mobile Phone Number"
                    placeholder="(XXX) XXX-XXXX"
                    register={phoneNumberRegister}
                    getValues={getValues}
                    readOnly={!isEditingProfile}
                    className={!isEditingProfile ? 'not-clickable' : undefined}
                    maxLength={13}
                    error={!!errors.phoneNumber}
                    isValid={phoneNumberFlags.isValidPhoneNumber}
                    isFocus={phoneNumberFlags.isFocusPhoneNumber}
                    isError={phoneNumberFlags.isErrorPhoneNumber}
                    helperText={errors.phoneNumber ? errors.phoneNumber.message : ''}
                    onChange={handlePhoneNumberChange}
                    onFocus={handleFocusPhoneNumber}
                    onBlur={handleBlurPhoneNumber}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormInputText
                    name="dateOfBirth"
                    label="DOB"
                    placeholder="--"
                    register={dateOfBirthRegister}
                    getValues={getValues}
                    readOnly
                    className="not-clickable"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormInputText
                    name="preferredName"
                    placeholder="--"
                    label="Preferred Name"
                    register={preferredNameRegister}
                    getValues={getValues}
                    readOnly={!isEditingProfile}
                    className={!isEditingProfile ? 'not-clickable' : undefined}
                  />
                </Grid>
                {isEditingProfile && (
                  <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button
                      pfVariant="text"
                      onClick={() => (isDirty ? setCancelDialogOpen(true) : toggleEditingProfile())}
                    >
                      <NunitoSansLink>Cancel</NunitoSansLink>
                    </Button>

                    <Button
                      label="Save"
                      pfVariant="filled"
                      onClick={handleClickSave}
                      disabled={!isDirty || hasErrors}
                    />
                  </Grid>
                )}
              </Grid>
            ) : (
              <FormSkeleton />
            )}
          </BoxStyles>
          <BoxStyles sx={{ marginBottom: theme.spacing(1) }} role="section">
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                mb: theme.spacing(1),
              }}
            >
              <Subtitle2 variant="h2" sx={{ color: theme.palette.text.primary }}>
                Address
              </Subtitle2>
              {!isEditingAddress && <Button label="Edit" onClick={toggleEditingAddress} pfVariant="text" />}
            </Box>
            <Box sx={{ mb: theme.spacing(2.5) }}>
              <Divider aria-hidden="true" />
            </Box>
            {profileData && statesData.length > 0 ? (
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <FormInputText
                    name="address.address1"
                    label="Address 1"
                    placeholder="--"
                    register={address1Register}
                    getValues={getValues}
                    readOnly={!isEditingAddress}
                    className={!isEditingAddress ? 'not-clickable' : undefined}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormInputText
                    name="address.address2"
                    label="Address 2"
                    placeholder="--"
                    register={address2Register}
                    getValues={getValues}
                    readOnly={!isEditingAddress}
                    className={!isEditingAddress ? 'not-clickable' : undefined}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormInputText
                    name="address.city"
                    label="City/Town"
                    placeholder="--"
                    register={cityRegister}
                    getValues={getValues}
                    readOnly={!isEditingAddress}
                    className={!isEditingAddress ? 'not-clickable' : undefined}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormInputSelect
                    name="address.regionAbbr"
                    label="State"
                    register={regionRegister}
                    defaultValue={getValues('address.regionAbbr')}
                    getValues={getValues}
                    readOnly={!isEditingAddress}
                    className={!isEditingAddress ? 'not-clickable' : undefined}
                    selectValues={stateOptions || []}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormInputText
                    name="address.postalCode"
                    label="Zip Code"
                    placeholder="--"
                    register={postalCodeRegister}
                    getValues={getValues}
                    maxLength={5}
                    readOnly={!isEditingAddress}
                    className={!isEditingAddress ? 'not-clickable' : undefined}
                    error={!!errors.address?.postalCode}
                    helperText={errors.address?.postalCode ? errors.address?.postalCode.message : ''}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormInputText
                    name="address.country"
                    label="Country"
                    placeholder="--"
                    register={countryRegister}
                    getValues={getValues}
                    readOnly
                    className="not-clickable"
                  />
                </Grid>

                {isEditingAddress && (
                  <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button
                      pfVariant="text"
                      onClick={() => (isDirty ? setCancelDialogOpen(true) : toggleEditingAddress())}
                    >
                      <NunitoSansLink>Cancel</NunitoSansLink>
                    </Button>
                    <Button
                      label="Save"
                      pfVariant="filled"
                      onClick={() => setDialogOpen(true)}
                      disabled={!isDirty || hasErrors}
                    />
                  </Grid>
                )}
              </Grid>
            ) : (
              <FormSkeleton />
            )}
          </BoxStyles>
        </form>
      </Box>
      <FormDialogs
        dialogOpen={dialogOpen}
        cancelDialogOpen={cancelDialogOpen}
        isLoading={isLoading}
        setDialogOpen={setDialogOpen}
        setCancelDialogOpen={setCancelDialogOpen}
        handleSubmit={handleSubmit}
        onSubmit={onSubmit}
        handleCancel={handleCancel}
      />
      <Snackbar
        open={successSnackbarOpen}
        message={'Your information has been uploaded successfully'}
        autoHideDuration={6000}
        sx={{ mb: '75px' }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        onClose={handleCloseSuccessSnackbar}
      />
    </>
  );
}
