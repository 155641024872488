import React from 'react';
import NotificationCard from './notification-card';
import { electivesNotificationProps } from './notification-card-props';


export default function ElectivesNotificationCard() {
  const notificationProps = electivesNotificationProps();
  return (
    <NotificationCard
      content={notificationProps.content}
      buttons={notificationProps.buttons}
      media={notificationProps.media}
      title={notificationProps.title}
    />
  );
}
