import { Button, Card, Typography, Icon } from '@pennfoster/pfc-design-system';
import { Course } from '../../types/course';
import {
  CardContent,
  Grid,
  useTheme,
  Box,
  Divider,
  CardHeader,
} from '@mui/material';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { Body1, HeadingH6, LabelLarge, Subtitle1 } from 'src/styles';
import { useContext } from 'react';
import EnrollmentContext from 'src/context/enrollment';
import { styled } from '@mui/material/styles';
import ProgressChip from '../../components/progress-chip';
import useCourseUrls from '../../hooks/use-course-url';
import engenTooltipText from 'src/constants/engen-card-tooltip';

const EngenCourseCard = ({
  course,
  setIsLoadingUrlCourses,
}: {
  course?: Course;
  setIsLoadingUrlCourses: (
    value: boolean | ((prevVar: boolean) => boolean)
  ) => void;
}) => {
  const sisCourseId = course?.sisStudentCourseId;

  // TODO: We don't have design mockups for the event that no courses are returned.
  if (!sisCourseId) {
    return (
      <Card sx={{ pl: 4, pr: 4, pb: 4, pt: 4 }}>
        <CardContent sx={{ padding: 3 }}>
          <Subtitle1 variant="h2">No Courses Available</Subtitle1>
        </CardContent>
      </Card>
    );
  }

  const theme = useTheme();
  const enrollmentContext = useContext(EnrollmentContext);
  const enrollmentId = enrollmentContext?.enrollment?.enrollmentId;
  if (enrollmentId) {
    useCourseUrls.enrollmentId = enrollmentId;
  }
  const { handleCourseRedirect } = useCourseUrls;

  const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))({
    [`& .${tooltipClasses.tooltip}`]: {
      maxWidth: 550,
    },
  });

  return (
    <Card sx={{ pl: 4, pr: 4, pb: 4, pt: 4 }}>
      <CardHeader
        title={
          <Box>
            <Grid container>
              <Grid item xs={6}>
                <Typography variant={'subtitle1'}>Current Course</Typography>
              </Grid>
              <Grid item xs={6} textAlign="right">
                <ProgressChip
                  status={course.status}
                  isStatusForLesson={false}
                />
              </Grid>
            </Grid>
          </Box>
        }
      />
      <Divider aria-hidden="true" />
      <CardContent>
        <Grid container>
          <Grid item xs={12}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                pt: 3,
              }}
            >
              <HeadingH6
                color={theme.palette.secondary.main}
                sx={{
                  pr: 1,
                }}
              >
                Entrenamiento en el Idioma Inglés
              </HeadingH6>
              <CustomWidthTooltip
                title={engenTooltipText}
                placement="bottom"
                arrow
              >
                <Icon.InfoOutlined
                  sx={{
                    color: theme.palette.secondary.main,
                  }}
                />
              </CustomWidthTooltip>
            </Box>
            <Body1
              sx={{
                pt: 3,
              }}
            >
              La plataforma EnGen te ayudará a mejorar tu inglés antes de
              comenzar tus cursos de secundaria con Penn Foster. La primera vez
              que ingreses a EnGen, se te pedirá que realices la{' '}
              <a
                href="https://engen.wistia.com/medias/8d66p0mc1p"
                target="_blank"
                rel="noreferrer"
              >
                Evaluación de Competencia
              </a>
              (PA), la cual evaluará tus habilidades actuales en inglés. Para
              obtener más información, visita la página de{' '}
              <a
                href="https://getengen.com/penn-foster-general-faq-es"
                target="_blank"
                rel="noreferrer"
              >
                Preguntas Frecuentes
              </a>
              .
            </Body1>
          </Grid>
        </Grid>
      </CardContent>
      <Grid item xs={12} textAlign={'right'}>
        <Box sx={{ pt: 3 }}>
          <Button
            pfVariant="filled"
            onClick={() =>
              handleCourseRedirect(
                course.sisStudentCourseId,
                setIsLoadingUrlCourses
              )
            }
          >
            <LabelLarge>Continue Learning</LabelLarge>
          </Button>
        </Box>
      </Grid>
    </Card>
  );
};
export default EngenCourseCard;
