import { ReactElement, useEffect, useState } from 'react';
import { JsxElement } from 'typescript';
import authTokens from 'src/auth/auth-tokens';
import { TokenHelper } from 'src/helpers/token-helper';
import { Outlet } from 'react-router-dom';
import UnauthorizedPage from 'src/pages/unauthorized';
import AuthLogin from 'src/pages/auth/login';

export function ProtectAdmin() {
  const [component, setComponent] = useState<ReactElement<JsxElement>>(<></>);
  const tokenHelper = new TokenHelper(authTokens.getAccessToken());
  const isAdmin = tokenHelper.isAdmin();
  const hasToken = tokenHelper.hasToken();

  useEffect(() => {
    if (hasToken && isAdmin) setComponent(<Outlet />);
    if (hasToken && !isAdmin) setComponent(<UnauthorizedPage />);
    if (!hasToken && !isAdmin) setComponent(<AuthLogin />);
  }, []);

  return component;
}
