import { getCourseUrl } from 'src/api/courses';

interface CourseUrl {
  [key: number]: string;
}

class CourseUrls {
  public _currentCourseId = -1;
  get currentCourseId() {
    return this._currentCourseId;
  }
  set currentCourseId(currentCourseId) {
    this._currentCourseId = currentCourseId;
  }
  private _enrollmentId = '';
  get enrollmentId() {
    return this._enrollmentId;
  }
  set enrollmentId(enrollmentId) {
    this._enrollmentId = enrollmentId;
  }

  private _urls: { [key: number]: string } = {};
  public get urls() {
    return this._urls;
  }
  private set urls(newUrls) {
    this._urls = newUrls;
  }

  public addUrl(newUrl: CourseUrl) {
    this.urls = {
      ...this._urls,
      ...newUrl,
    };
  }

  public _queryStatus: 'loading' | 'idle' | 'error' | 'success' = 'idle';
  get queryStatus() {
    return this._queryStatus;
  }
  set queryStatus(status) {
    this._queryStatus = status;
  }

  private _error = false;
  get error() {
    return this._error;
  }
  set error(b: boolean) {
    this._error = b;
  }

  public handleCourseRedirect = async (
    courseId: number,
    setLoadingState: (isLoading: boolean) => void
  ) => {
    setLoadingState(true);
    try {
      
      const courseUrl = await this.getCourseUrl(courseId);
      if (courseUrl) {
        await window.location.replace(courseUrl);
      }
    } catch (error) {
      setLoadingState(false);
      console.error('Error during course redirect:', error);
    }
  };

  private getCourseUrl = (courseId: number): Promise<string> => {
    if (this.urls[courseId]) {
      return Promise.resolve(this.urls[courseId]);
    }
    this.queryStatus = 'loading';
    return getCourseUrl(this.enrollmentId, courseId)
      .then((res) => {
        this.queryStatus = 'success';
        this.addUrl({ [courseId]: res.data });
        return res.data;
      })
      .catch((error) => {
        this.queryStatus = 'error';
        throw new Error(
          `Failed to get course URL for courseId ${courseId}: ${
            error.message || 'An error occurred'
          }`
        );
      });
  };
}
const courseUrls = new CourseUrls();
export default courseUrls;
