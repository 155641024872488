import axios from 'axios';
import AuthTokens from './auth-tokens';
import { AUTH_HOST } from './auth-host';
const config = process.env;

export async function refreshToken() {
  const refreshToken = AuthTokens.getRefreshToken();

  const endpoint = `https://${AUTH_HOST}/${config.REACT_APP_AUTH_TENANT}.onmicrosoft.com/${config.REACT_APP_AUTH_POLICY}/oauth2/v2.0/token`;

  const params = new URLSearchParams();
  params.append('grant_type', 'refresh_token');
  params.append('client_id', `${config.REACT_APP_AUTH_CLIENT_ID}`);
  params.append('refresh_token', refreshToken);
  params.append('scope', `openid ${config.REACT_APP_SCOPE_OF_BACK_WEB_API}`);

  const res = await axios
    .post(endpoint, params.toString(), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    })
    .catch((err) => {
      console.error('Error refreshing token:', err);
    });

  if (res) {
    AuthTokens.setAccessToken(res.data.access_token);
    AuthTokens.setRefreshToken(res.data.refresh_token);
    sessionStorage.setItem('refreshToken', res.data.refresh_token);
    return AuthTokens.getAccessToken();
  }

  return null;
}
