import React, { useContext, useEffect, useMemo, useReducer } from 'react';
import { ProgramDTO } from 'src/types/enrollment';
import {
  CalculateSalesTaxRequest,
  ChargeStudent,
  ChargeStudentItem,
  NotificationPaymentErrorCardProps,
  PaymentMethod,
  PaymentTaxesResponse,
  SalesTaxItem,
  ScheduledPayment,
  ScheduledPaymentData,
} from 'src/types/payments';
import { PAYMENTS_OVERVIEW } from 'src/routes/routemap';
import { initialState, reducer } from './state';
import { useNavigate } from 'react-router-dom';
import { SubmitHandler, useForm } from 'react-hook-form';
import { AxiosResponse, AxiosError } from 'axios';
import { useQuery } from 'react-query';
import { getEnrollmentScheduledPayments, getPaymentMethods } from 'src/api';
import { getPaymentTaxes } from 'src/api/enrollments';
import { makePayment } from 'src/api/payment';
import EnrollmentContext from 'src/context/enrollment';
import UserContext from 'src/context/user';
import { roundNumber } from 'src/helpers/format';
import QueryKeys from 'src/types/query-keys';
import FormView from './make-a-payment-form';
import { isDateOnOrAfterToday } from 'src/helpers/compare-date';

export interface IFormInput {
  amount: string;
  sisPaymentPlanId: number;
  paymentPlanSisId?: number;
  studentId: number;
  enrollmentId: string;
  howToPay: boolean;
  paymentMethodId: number;
  programDisplayName: string;
}

interface makeAPaymentFormProps {
  onNotificationChange: React.Dispatch<React.SetStateAction<boolean>>;
}

function getNextPaymentDueDate(
  newSelectedScheduledPayments: ScheduledPayment[],
  paymentsScheduled: ScheduledPayment[]
) {
  return newSelectedScheduledPayments.length === paymentsScheduled.length
    ? undefined
    : paymentsScheduled[newSelectedScheduledPayments.length].dueDate.toString();
}

export default function MakeAPaymentForm({ onNotificationChange }: makeAPaymentFormProps) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { enrollment } = useContext(EnrollmentContext);
  const { user } = useContext(UserContext);
  const {
    individualPaymentsSelected,
    addPaymentMethodOpen,
    mostRecentPaymentDueDate,
    newCard,
    defaultCard,
    paymentMethods,
    selectedscheduledPayments,
    amount,
    paymentTaxesResponse,
    selectedProgram,
    salesTaxRequest,
    notificationProps,
  } = state;

  const navigate = useNavigate();
  function handleCancelClick() {
    navigate(PAYMENTS_OVERVIEW);
  }

  useEffect(() => {
    onNotificationChange(notificationProps?.errorTitle === 'Success');
  }, [notificationProps?.errorTitle]);

  const {
    control,
    setValue,
    handleSubmit: handleSubmit,
    watch,
    formState: { isValid },
  } = useForm<IFormInput>({
    defaultValues: {
      howToPay: true,
      enrollmentId: enrollment?.enrollmentId,
      studentId: user?.sisId,
      sisPaymentPlanId: undefined,
    },
    mode: 'onSubmit',
  });

  const formData = watch();

  const scheduledPaymentsQuery = useQuery(
    [QueryKeys.GET_ENROLLMENT_SCHEDULED_PAYMENTS, selectedProgram?.sisEnrollmentId],
    async () => {
      if (selectedProgram?.enrollmentId) {
        const resp = await getEnrollmentScheduledPayments(selectedProgram?.enrollmentId);
        return resp;
      }
    },
    {
      staleTime: 0,
      onError: (error) => console.error(error),
    }
  );

  const { paymentsScheduled, total } = useMemo((): ScheduledPaymentData => {
    const scheduledPaymentsData = scheduledPaymentsQuery.data?.data;

    if (scheduledPaymentsData && scheduledPaymentsData.paymentsScheduled?.length > 0) {
      if (selectedscheduledPayments.length === 0) {
        dispatch({
          type: 'SET_SELECTED_SCHEDULED_PAYMENTS',
          payload: [scheduledPaymentsData.paymentsScheduled[0] ?? []],
        });
        dispatch({
          type: 'SET_AMOUNT',
          payload: individualPaymentsSelected
            ? scheduledPaymentsData.paymentsScheduled[0].amountDue ?? 0
            : scheduledPaymentsData.total ?? 0,
        });
        const paymentWithSisPaymentPlanId = scheduledPaymentsData.paymentsScheduled.find(
          (payment) => payment.sisPaymentPlanId !== null
        );
        if (paymentWithSisPaymentPlanId) {
          setValue('sisPaymentPlanId', paymentWithSisPaymentPlanId.sisPaymentPlanId);
        }
      }
      if (!individualPaymentsSelected) {
        dispatch({
          type: 'SET_AMOUNT',
          payload: scheduledPaymentsData.total,
        });
      }

      return {
        total: scheduledPaymentsData.total,
        paymentsScheduled: scheduledPaymentsData.paymentsScheduled,
      };
    }
    return {
      total: 0,
      paymentsScheduled: [],
    };
  }, [
    scheduledPaymentsQuery.data,
    scheduledPaymentsQuery.data?.data.paymentsScheduled,
    scheduledPaymentsQuery.data?.data.total,
  ]);

  const paymentMethodsQuery = useQuery([QueryKeys.GET_PAYMENT_METHOD_LIST], async () => await getPaymentMethods(), {
    onSuccess: (resp: AxiosResponse<PaymentMethod[]>) => {
      dispatch({ type: 'SET_PAYMENT_METHODS', payload: resp.data });
      dispatch({
        type: 'SET_DEFAULT_CARD',
        payload: resp.data.find((card) => card.isDefault),
      });
    },
    onError: (error) => console.error(error),
  });

  const paymentTaxQuery = useQuery(
    [QueryKeys.GET_PAYMENT_TAXES],
    async () => {
      dispatch({ type: 'SET_IS_LOADING_TAXES', payload: true });
      if (salesTaxRequest && enrollment?.enrollmentId) {
        return await getPaymentTaxes(salesTaxRequest, enrollment?.enrollmentId);
      }
      return null;
    },
    {
      onSuccess: (resp: AxiosResponse<PaymentTaxesResponse>) => {
        dispatch({ type: 'SET_IS_ERROR_ON_TAXES', payload: false });
        if (resp && resp.data) {
          dispatch({ type: 'SET_PAYMENT_TAXES_RESPONSE', payload: resp.data });
        }
        dispatch({ type: 'SET_IS_LOADING_TAXES', payload: false });
      },
      onError: (error) => {
        console.error('Error: ', error);
        dispatch({ type: 'SET_IS_LOADING_TAXES', payload: false });
        dispatch({ type: 'SET_IS_ERROR_ON_TAXES', payload: true });
      },
      enabled: !!salesTaxRequest,
    }
  );

  function setDefaultPaymentMethod(defaultCard: PaymentMethod | undefined) {
    if (defaultCard) {
      dispatch({ type: 'SET_DEFAULT_CARD', payload: defaultCard });
    } else {
      dispatch({ type: 'SET_DEFAULT_CARD', payload: paymentMethods[0] });
    }
  }

  function resetAmount(resetForIndividual: boolean) {
    if (resetForIndividual) {
      const paymentsToSet = paymentsScheduled.length > 0 ? [paymentsScheduled[0]] : [];
      const nextPaymentDueDate = getNextPaymentDueDate(paymentsToSet, paymentsScheduled);
      dispatch({
        type: 'SET_AMOUNT',
        payload: paymentsScheduled[0]?.amountDue,
      });
      dispatch({
        type: 'SET_SELECTED_SCHEDULED_PAYMENTS',
        payload: paymentsToSet,
      });
      dispatch({ type: 'SET_MOST_RECENT_PAYMENT_DUE_DATE', payload: nextPaymentDueDate });
    } else {
      dispatch({ type: 'SET_MOST_RECENT_PAYMENT_DUE_DATE', payload: undefined });
      dispatch({ type: 'SET_AMOUNT', payload: total });
    }
  }

  const onClickHowToPay = (individualPayments: boolean) => {
    dispatch({
      type: 'SET_INDIVIDUAL_PAYMENTS_SELECTED',
      payload: individualPayments,
    });
    resetAmount(individualPayments);
  };

  const setAddPaymentMethodOpen = (isOpen: boolean) =>
    dispatch({ type: 'SET_ADD_PAYMENT_METHOD_OPEN', payload: isOpen });

  const setDialogOpen = (isOpen: boolean) => dispatch({ type: 'SET_DIALOG_OPEN', payload: isOpen });

  const setIsLoading = (isLoading: boolean) => dispatch({ type: 'SET_IS_LOADING', payload: isLoading });

  const setNewCard = (newCard: PaymentMethod) => dispatch({ type: 'SET_NEW_CARD', payload: newCard });

  const setNotificationProps = (notificationProps: NotificationPaymentErrorCardProps | null) =>
    dispatch({ type: 'SET_NOTIFICATION_PROPS', payload: notificationProps });

  const handleClose = () => {
    setAddPaymentMethodOpen(false);
  };

  const onClickPaymentMethod = (paymentMethod: PaymentMethod) => {
    dispatch({ type: 'SET_DEFAULT_CARD', payload: paymentMethod });
  };

  const handleSelectProgram = (program: ProgramDTO) => {
    dispatch({ type: 'SET_SELECTED_PROGRAM', payload: program });
    dispatch({
      type: 'SET_SELECTED_SCHEDULED_PAYMENTS',
      payload: [],
    });
    scheduledPaymentsQuery.refetch();
  };

  const cardDefaultError: NotificationPaymentErrorCardProps = {
    errorTitle: 'There was an error processing your card',
    errorMessage: 'There was an error making this transaction. No charges were made to your account. Please try again.',
    makePayment: true,
    setNotificationProps,
  };

  const buildSalesTaxRequestObject = (data: IFormInput): CalculateSalesTaxRequest => {
    const salesTaxItems: SalesTaxItem[] = [];
    if (paymentsScheduled.length > 0) {
      paymentsScheduled.map((item) => {
        salesTaxItems.push({
          amount: item.amountDue,
          type: item.type == 'Fee' ? 'Fee' : 'Tuition',
          sisPaymentScheduledId: item.sisPaymentScheduledId,
          termId: item.sisTermId,
        });
      });
    }
    let salesTax: CalculateSalesTaxRequest = {
      payInFull: true,
      salesTaxItems: salesTaxItems.length > 0 ? salesTaxItems : [],
      sisPaymentMethodId: defaultCard!.id!,
    };

    if (individualPaymentsSelected) {
      const salesTaxItems: SalesTaxItem[] = selectedscheduledPayments.map((payment) => {
        if (payment.type === 'Fee') {
          return {
            type: payment.type,
            amount: payment.amountDue,
            termId: payment.sisTermId,
          } as SalesTaxItem;
        }
        return {
          type: payment.type,
          sisPaymentScheduledId: payment?.sisPaymentScheduledId,
          amount: payment.amountDue,
          termId: payment.sisTermId,
        } as SalesTaxItem;
      });

      salesTax = {
        payInFull: false,
        sisPaymentPlanId: data.sisPaymentPlanId,
        sisPaymentMethodId: defaultCard!.id!,
        salesTaxItems: salesTaxItems,
      };
    }
    dispatch({ type: 'SET_SALES_TAX_REQUEST', payload: salesTax });

    return salesTax;
  };

  const buildPayInFullObject = (): ChargeStudent => {
    const chargeStudentLines: ChargeStudentItem[] | undefined = paymentsScheduled.map((payment) => {
      return {
        amount: payment.amountDue,
        taxAmount:
          paymentTaxesResponse?.salesTaxTransactions?.find((x) => x.termId === payment.sisTermId)?.taxAmount ?? 0,
        type: payment.type == 'Fee' ? 'Fee' : 'Tuition',
        sisPaymentScheduledId: payment.sisPaymentScheduledId,
        termId: payment.sisTermId,
      };
    });

    return {
      payInFull: true,
      paymentType: defaultCard?.type ?? 'unknown',
      totalAmount: amount + (paymentTaxesResponse?.totalAmount ?? 0),
      totalTaxAmount: paymentTaxesResponse?.totalAmount ?? 0,
      chargeStudentItems: chargeStudentLines.length > 0 ? chargeStudentLines : [],
      sisPaymentMethodId: defaultCard!.id!,
      sisPaymentPlanId: 0,
    };
  };

  const buildPayIndividualObject = (data: IFormInput): ChargeStudent => {
    const chargeItems: ChargeStudentItem[] | undefined = selectedscheduledPayments.map((payment) => {
      return {
        type: payment?.type == 'Fee' ? 'Fee' : 'Tuition',
        amount: payment?.amountDue,
        taxAmount:
          paymentTaxesResponse?.salesTaxTransactions?.find((x) => x.termId === payment?.sisTermId)?.taxAmount ?? 0,
        termId: payment?.sisTermId,
        sisPaymentScheduledId: payment?.type == 'Fee' ? undefined : payment?.sisPaymentScheduledId,
      } as ChargeStudentItem;
    });

    return {
      payInFull: false,
      paymentType: defaultCard?.type ?? 'unknown',
      totalAmount: amount + (paymentTaxesResponse?.totalAmount ?? 0),
      totalTaxAmount: paymentTaxesResponse?.totalAmount ?? 0,
      sisPaymentPlanId: data.sisPaymentPlanId,
      sisPaymentMethodId: defaultCard!.id!,
      chargeStudentItems: chargeItems!,
    };
  };

  const buildChargeStudentObject = (data: IFormInput): ChargeStudent => {
    return individualPaymentsSelected ? buildPayIndividualObject(data) : buildPayInFullObject();
  };

  const onSubmit: SubmitHandler<IFormInput> = (data) => {
    setIsLoading(true);
    if (!addPaymentMethodOpen) {
      // TODO: need to handle form submit if there is no enrollment ID set
      if (!selectedProgram) {
        return;
      }
      const chargeStudent = buildChargeStudentObject(data);

      return new Promise((resolve, reject) => {
        makePayment(selectedProgram.enrollmentId, chargeStudent)
          .then((result: unknown) => {
            resolve(result);
            setNotificationProps({
              errorTitle: 'Success',
              errorMessage: 'Success',
              makePayment: true,
              setNotificationProps,
            });
            setDialogOpen(false);
            setIsLoading(false);
            dispatch({
              type: 'SET_SELECTED_SCHEDULED_PAYMENTS',
              payload: [],
            });
            scheduledPaymentsQuery.refetch();
          })
          .catch((error: AxiosError) => {
            setNotificationProps(cardDefaultError);
            setDialogOpen(false);
            setIsLoading(false);
            reject(error);
          });
      });
    }
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>, payment: ScheduledPayment) => {
    const { checked } = event.target;
    const paymentAmount = payment.amountDue;

    if (checked) {
      const newAmount = roundNumber(paymentAmount + amount);
      const newSelectedScheduledPayments = [payment, ...selectedscheduledPayments];
      // undefined if there are no more payments left to select
      const nextPaymentDueDate = getNextPaymentDueDate(newSelectedScheduledPayments, paymentsScheduled);

      dispatch({
        type: 'SET_SELECTED_SCHEDULED_PAYMENTS',
        payload: newSelectedScheduledPayments,
      });

      dispatch({
        type: 'SET_MOST_RECENT_PAYMENT_DUE_DATE',
        payload: nextPaymentDueDate,
      });

      dispatch({ type: 'SET_AMOUNT', payload: newAmount });
    } else {
      const newAmount = roundNumber(amount - paymentAmount);

      dispatch({
        type: 'SET_MOST_RECENT_PAYMENT_DUE_DATE',
        payload: paymentsScheduled[selectedscheduledPayments.length - 1].dueDate.toString(),
      });

      dispatch({
        type: 'SET_SELECTED_SCHEDULED_PAYMENTS',
        payload: selectedscheduledPayments.filter((p) => p !== payment),
      });

      dispatch({ type: 'SET_AMOUNT', payload: newAmount });
    }
  };

  const arePaymentsDueBeforeToday = selectedscheduledPayments.some((payment) =>
    payment?.dueDate ? new Date(payment.dueDate) < new Date() : true
  );

  // if there are no more payments mostRecentPaymentDueDate is undefined and can return true
  const isMostRecentPaymentOnOrAfterToday =
    mostRecentPaymentDueDate === undefined ? true : isDateOnOrAfterToday(mostRecentPaymentDueDate);

  const selectedAmountGreaterThanOrEqualsTotal = amount >= total;

  const shouldShouldAlert =
    individualPaymentsSelected &&
    arePaymentsDueBeforeToday &&
    !selectedAmountGreaterThanOrEqualsTotal &&
    !isMostRecentPaymentOnOrAfterToday;

  const handleClickChange = async () => {
    dispatch({ type: 'SET_IS_CHANGED', payload: !state.isChanged });
  };

  useEffect(() => {
    if (paymentMethodsQuery.data?.data) {
      dispatch({
        type: 'SET_PAYMENT_METHODS',
        payload: paymentMethodsQuery.data?.data,
      });
      const defaultCard = paymentMethodsQuery.data?.data.find((card) => card.isDefault);
      setDefaultPaymentMethod(defaultCard);
    }
  }, []);

  useEffect(() => {
    if (newCard) {
      dispatch({
        type: 'SET_PAYMENT_METHODS',
        payload: [newCard, ...paymentMethods],
      });
      dispatch({ type: 'SET_DEFAULT_CARD', payload: newCard });
      dispatch({ type: 'SET_IS_CHANGED', payload: false });
    }
  }, [newCard]);

  useEffect(() => {
    onNotificationChange(notificationProps?.errorTitle === 'Success');
  }, [notificationProps?.errorTitle]);

  useEffect(() => {
    paymentTaxQuery.refetch();
  }, [salesTaxRequest]);

  return (
    <FormView
      state={state}
      handleSubmit={handleSubmit}
      onSubmit={onSubmit}
      handleSelectProgram={handleSelectProgram}
      notificationProps={notificationProps}
      onClickHowToPay={onClickHowToPay}
      control={control}
      total={total}
      handleCheckboxChange={handleCheckboxChange}
      buildSalesTaxRequestObject={buildSalesTaxRequestObject}
      handleClickChange={handleClickChange}
      onClickPaymentMethod={onClickPaymentMethod}
      setAddPaymentMethodOpen={setAddPaymentMethodOpen}
      setNewCard={setNewCard}
      handleCancelClick={handleCancelClick}
      shouldShouldAlert={shouldShouldAlert}
      isValid={isValid}
      setDialogOpen={setDialogOpen}
      handleClose={handleClose}
      paymentsScheduled={paymentsScheduled}
      formData={formData}
    />
  );
}
