export enum AccountStatuses {
  Current = 'current',
  Paid = 'paid',
  Suspended = 'suspended',
  Deactivated = 'deactivated',
  Past_due = 'pastdue',
}

export type AccountStatusesLabel =
  | 'Paid'
  | 'Current'
  | 'Past Due'
  | 'Suspended'
  | 'Deactivated';

export const AccountStatusesLabels: Record<
  AccountStatuses,
  AccountStatusesLabel
> = {
  [AccountStatuses.Paid]: 'Paid',
  [AccountStatuses.Current]: 'Current',
  [AccountStatuses.Past_due]: 'Past Due',
  [AccountStatuses.Suspended]: 'Suspended',
  [AccountStatuses.Deactivated]: 'Deactivated',
};
