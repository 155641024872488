import React from 'react';
import { createBrowserRouter, createRoutesFromElements, Route, RouterProvider } from 'react-router-dom';
import Crumb from 'src/components/breadcrumbs/crumb';
import PageNotFoundModal from 'src/components/error-modals/page-not-found';
import { AdminPage } from 'src/pages/admin';
import AuthCallback from 'src/pages/auth/callback';
import AuthLogin from 'src/pages/auth/login';
import AuthLogout from 'src/pages/auth/logout';
import AuthLogoutCallback from 'src/pages/auth/logout_callback';
import ConfirmationPayment from 'src/pages/confirmation-payment';
import Dashboard from 'src/pages/dashboard';
import Electives from 'src/pages/electives';
import MakeAPayment from 'src/pages/make-a-payment';
import Pathways from 'src/pages/pathways';
import PaymentMethods from 'src/pages/payment-methods';
import Payments from 'src/pages/payments';
import Profile from 'src/pages/profile';
import Programs from 'src/pages/programs';
import UnauthorizedPage from 'src/pages/unauthorized';
import PaymentsGuard from './guards/payments-guard';

import { CRUMBS } from './breadcrumbmap';
import ElectivesEnrollmentGuard from './guards/electives-enrollment-guard';
import PathwaysEnrollmentGuard from './guards/pathway-enrollment-guard';
import { ProtectAdmin } from './protected-admin';
import PrivateRoute from './protected-route';
import ROUTES from './routemap';
import SessionTimeoutPage from 'src/pages/session-timeout';
import ProcessingEnrollmentPage from 'src/pages/processing-enrollment';
import EnrollmentGuard from './guards/enrollment-guard';
import AuthForgotSignin from 'src/pages/auth/forgot-signin';
import AuthResetPassword from 'src/pages/auth/reset-password';
import AuthHelp from 'src/pages/auth/help';
import AuthError from 'src/pages/auth/error';
import AuthAdmin from 'src/pages/auth/admin';
import CachedVersionCheck from './cached-version-check';

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" handle={{ crumb: () => <Crumb link={CRUMBS.DASHBOARD} icon="Home" /> }}>
      <Route element={<CachedVersionCheck/>}>
        <Route element={<PrivateRoute />}>
          <Route path={ROUTES.DASHBOARD}>
            <Route element={<EnrollmentGuard />}>
              <Route index element={<Dashboard />} />
              <Route element={<PaymentsGuard />}>
                <Route
                  path={ROUTES.PAYMENTS_OVERVIEW}
                  handle={{
                    crumb: () => <Crumb link={CRUMBS.PAYMENTS_OVERVIEW} />,
                  }}
                >
                  <Route element={<Payments />} path="" />
                  <Route
                    path={ROUTES.MAKE_A_PAYMENT}
                    element={<MakeAPayment />}
                    handle={{
                      crumb: () => <Crumb link={CRUMBS.MAKE_A_PAYMENT} />,
                    }}
                  />
                  <Route
                    path={ROUTES.MANAGE_PAYMENT}
                    element={<PaymentMethods />}
                    handle={{
                      crumb: () => <Crumb link={CRUMBS.MANAGE_PAYMENT} />,
                    }}
                  />
                </Route>
              </Route>
              <Route path={ROUTES.COURSES} handle={{ crumb: () => <Crumb link={CRUMBS.COURSES} /> }}>
                <Route element={<Programs />} path="" />
                <Route element={<ElectivesEnrollmentGuard />}>
                  <Route
                    path={ROUTES.ELECTIVES}
                    element={<Electives />}
                    handle={{ crumb: () => <Crumb link={CRUMBS.ELECTIVES} /> }}
                  />
                </Route>
                <Route element={<PathwaysEnrollmentGuard />}>
                  <Route
                    path={ROUTES.PATHWAY}
                    element={<Pathways />}
                    handle={{ crumb: () => <Crumb link={CRUMBS.PATHWAY} /> }}
                  />
                </Route>
              </Route>
              <Route
                path={ROUTES.PROFILE}
                element={<Profile />}
                handle={{ crumb: () => <Crumb link={CRUMBS.PROFILE} /> }}
              />

              <Route path={ROUTES.CONFIRMATION_PAYMENT} element={<ConfirmationPayment />} />
            </Route>
          </Route>
          <Route element={<ProtectAdmin />}>
            <Route path={ROUTES.ADMIN} element={<AdminPage />} handle={{ crumb: () => <Crumb link={CRUMBS.ADMIN} /> }} />
          </Route>
        </Route>
        <Route path={ROUTES.UNAUTHORIZED} element={<UnauthorizedPage />} />
        <Route path={ROUTES.SESSION_TIMEOUT} element={<SessionTimeoutPage />} />
        <Route path={ROUTES.PROCESSING_ENROLLMENT} element={<ProcessingEnrollmentPage />} />
        <Route path={ROUTES.AUTH}>
          <Route path={ROUTES.AUTH_CALLBACK} element={<AuthCallback />} />
          <Route path={ROUTES.AUTH_LOGOUT} element={<AuthLogout />} />
          <Route path={ROUTES.AUTH_LOGOUT_CALLBACK} element={<AuthLogoutCallback />} />
          <Route path={ROUTES.AUTH_LOGIN} element={<AuthLogin />} />
          <Route path={ROUTES.AUTH_RESET_PASSWORD} element={<AuthResetPassword />} />
          <Route path={ROUTES.AUTH_FORGOT_SIGNIN} element={<AuthForgotSignin />} />
          <Route path={ROUTES.AUTH_HELP} element={<AuthHelp />} />
          <Route path={ROUTES.AUTH_ERROR} element={<AuthError />} />
          <Route path={ROUTES.AUTH_ADMIN} element={<AuthAdmin />} />
        </Route>
        <Route path="*" element={<PageNotFoundModal />} />
      </Route>
    </Route>
  )
);

export default function AppRoutes() {
  return <RouterProvider router={router} />;
}