import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import FeEvents from 'src/events/fe-events';
import { ELECTIVES } from 'src/routes/routemap';
import QueryKeys from 'src/types/query-keys';

import { Button } from '@pennfoster/pfc-design-system';

import { getUser } from '../../api';
import { ReactComponent as ChooseElectivesSvg } from '../../assets/svg/choose-electives.svg';
import { useElectivesRoutes } from 'src/hooks/electives';

const electivesNotificationProps = () => {
  const { electivePathwayRoute }= useElectivesRoutes();
  const navigateUrl = electivePathwayRoute;
  
  const userQuery = useQuery(QueryKeys.GET_USER, getUser);

  return {
    media: <ChooseElectivesSvg />,
    title: 'Choose Your Electives!',
    content:
      'Explore new subjects and tailor your education to your interests.',
    buttons: [
      <Button
        key="Select Electives"
        sx={{ m: 1 }}
        size="small"
        variant="outlined"
        component={Link}
        to={navigateUrl}
        onClick={() =>
          FeEvents.trackNamedEvent({
            eventName: 'SelectElectivesClickEvent',
            user: userQuery.data?.data,
            dataSource: {},
          })
        }
      >
        Select Electives
      </Button>,
    ],
  };
};

export { electivesNotificationProps };
