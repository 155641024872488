import { getDefaultStore } from 'jotai';
import { BaseSignalRConnection } from './base-signalr-connection';
import { createClickStreamData } from './click-stream-data-factory';
import { userAtom } from 'src/state';
import { ClickStreamEventData, StreamEvent, trackedEvent } from 'src/types/streamed-events';
const atomStore = getDefaultStore()
class FeEvents extends BaseSignalRConnection {
  constructor() {
    super(process.env.REACT_APP_SIGNALR_FE_EVENTS_URL || '');
    this.init();
  }

  // Overriding the startConnection to add custom behavior
  public async startConnection(): Promise<void> {
    await super.startConnection();
  }
  private async init() {
    try {
      await this._connection.start();
      this._isConnected = true;
      this._connection.on('Registered', (message: string) => {
        console.info(message);
      });
    } catch (error) {
      this._isConnected = false;
    }
  }

  // This method is specific to ClickStream and handles click events
  handleClickStreamEvent(e: MouseEvent): void {
    const csEvent = createClickStreamData(e);
    this.trackClickEvent(csEvent)
  }

  trackClickEvent(clickStreamEventData: ClickStreamEventData) {
    const eventObject = [{
      EventType: 'Click-Stream',
      User: {
        UserID: atomStore.get(userAtom)?.id.toString(),
        // TODO: need to add session ID after new login is finished
      },
      EventData: clickStreamEventData,
    }];
    this.trackEvent(eventObject)
  }

  trackNamedEvent(object: trackedEvent) {
    const eventObject = [{
      EventType: 'Named-Event',
      User: {
        UserID: atomStore.get(userAtom)?.id.toString(),
        // TODO: need to add session ID after new login is finished
      },
      EventData: {
        eventName: object.eventName,
        dateTime: new Date(),
        dataSource: object.dataSource,
      }
    }];
    this.trackEvent(eventObject)
  }

  trackPendoEvent(object: trackedEvent) {
    const eventObject = [{
      EventType: 'Pendo-Event',
      User: {
        UserID: atomStore.get(userAtom)?.id.toString(),
        // TODO: need to add session ID after new login is finished
      },
      EventData: {
        eventName: object.eventName,
        dateTime: new Date(),
        dataSource: object.dataSource,
      }
    }];
    this.trackEvent(eventObject)
  }

  trackEvent(events: StreamEvent[]) {
    if (this.isConnected) {
      this.connection.send('SendJsonMessage', JSON.stringify(events));
    }
  }
}

export default new FeEvents();