import { atom } from "jotai";
import { EmailChange } from "src/types/email-change";
  
export const initialEmailChangeState = {
    firstEmail: '',
    firstStudentNumber: '',
    secondEmail:'',
    secondStudentNumber: '',
}

export const studentNumberAtom = atom<string>('');
export const reasonAtom = atom<string>('');
export const isImpersonatingAtom = atom<boolean>(false);
export const emailChangeAtom = atom<EmailChange>(initialEmailChangeState);
