import React, { useContext } from 'react';
import {
  CardContent,
  CardActions,
  Box,
  Typography,
  useTheme,
} from '@mui/material';
import { Button, Card } from '@pennfoster/pfc-design-system';
import { Link } from 'react-router-dom';
import FeEvents from 'src/events/fe-events';
import { MANAGE_PAYMENT, MAKE_A_PAYMENT } from 'src/routes/routemap';
import UserContext from 'src/context/user';
import ErrorPaymentIcon from 'src/assets/svg/ErrorPayment.svg';
import { NotificationPaymentErrorCardProps } from 'src/types/payments';

export default function NotificationPaymentErrorCard(
  notificationProps: NotificationPaymentErrorCardProps
) {
  const { user } = useContext(UserContext);
  const theme = useTheme();

  return (
    <>
      <Card
        pfVariant="default"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          justifyContent: 'space-between',
          maxWidth: '460px',
        }}
      >
        <CardContent
          sx={{
            display: 'flex',
            flexDirection: 'column',
            textAlign: 'center',
            margin: 0,
            padding: 0,
          }}
        >
          <Box
            sx={{
              backgroundColor: 'rgba(255, 0, 61, 1);',
              height: '60px',
              margin: 0,
              position: 'relative',
            }}
          >
            <Box
              sx={{
                height: '43.33px',
                textAlign: 'center',
                position: 'absolute',
                top: '30%',
                left: '50%',
                transform: 'translate(-50%, 30%)',
              }}
            >
              <img src={ErrorPaymentIcon} alt="Error Icon" />
            </Box>
          </Box>

          <Typography
            sx={{
              color: 'rgba(163, 0, 39, 1)',
              fontFamily: 'Merriweather',
              fontSize: '24px',
              fontWeight: 400,
              lineHeight: '32px',
              letterSpacing: '0em',
              margin: '30px 64px',
              textAlign: 'center',
            }}
          >
            {notificationProps.errorTitle}
          </Typography>

          <Box sx={{ margin: '0px 64px' }}>
            <Typography
              sx={{
                fontFamily: 'Nunito Sans',
                fontSize: '16px',
                fontWeight: 400,
                lineHeight: '24px',
                letterSpacing: '0em',
                textAlign: 'center',
                marginBottom: theme.spacing(4),
              }}
            >
              {notificationProps.errorMessage}
            </Typography>
          </Box>
        </CardContent>
        <CardActions sx={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            sx={{
              m: 1,
              textAlign: 'center',
            }}
            size="small"
            color="primary"
            pfVariant="filled"
            component={Link}
            to={notificationProps.makePayment ? MAKE_A_PAYMENT : MANAGE_PAYMENT}
            onClick={() => {
              if (notificationProps) {
                notificationProps.setNotificationProps(null);
              }
              FeEvents.trackNamedEvent({
                eventName: 'MakeAPaymentClick',
                user,
                dataSource: {},
              });
            }}
          >
            {notificationProps.makePayment
              ? 'Go to Make a Payment'
              : 'Manage Payment Methods'}
          </Button>
        </CardActions>
      </Card>
    </>
  );
}
