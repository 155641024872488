import React from 'react';
import svg from 'src/assets/svg/error-models/there-was-an-error.svg';
import { SUPPORT_PHONE_NUMBER } from 'src/constants/phone';
import { AUTH_LOGOUT } from 'src/routes/routemap';

import ErrorModal from './error-modal';
import { useNavigate } from 'react-router-dom';

export default function UserNotFoundModal() {
  const navigate = useNavigate();

  const headline = `Yikes! There Was an Error.`;

  const body = `We can’t access required information. You need to contact us at
  ${SUPPORT_PHONE_NUMBER} to determine next steps to fix this issue.`;

  const buttonOptions = {
    text: 'Back to login',
    onClick: () => navigate(AUTH_LOGOUT),
  };

  return (
    <ErrorModal
      headline={headline}
      body={body}
      svg={svg}
      secondaryButtonOpts={buttonOptions}
    />
  );
}
