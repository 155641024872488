import React from 'react';
import { useTheme } from '@mui/material';
import { Chip } from '@pennfoster/pfc-design-system';
import {
  AccountStatuses,
  AccountStatusesLabels,
} from 'src/types/account-status';

interface Props {
  params: string;
}

export default function AccountStatusesChip({ params }: Props) {
  const theme = useTheme();
  const statusColorMap = {
    [AccountStatuses.Past_due]: {
      background: theme.palette.warning.light,
      border: theme.palette.warning.main,
    },
    [AccountStatuses.Current]: {
      background: theme.palette.secondary.container,
      border: theme.palette.secondary.light,
    },
    [AccountStatuses.Paid]: {
      background: theme.palette.success.container,
      border: theme.palette.success.light,
    },
    [AccountStatuses.Suspended]: {
      background: theme.palette.error.container,
      border: theme.palette.error.light,
    },
    [AccountStatuses.Deactivated]: {
      background: theme.palette.background.default,
      border: theme.palette.neutral[80],
    },
  };

  const color = statusColorMap[params as keyof typeof statusColorMap] ?? {
    background: theme.palette.background.light,
    border: theme.palette.neutral[80],
  };

  return (
    <Chip
      aria-label={`Status: ${[
        AccountStatusesLabels[params as AccountStatuses],
      ]}`}
      borderColor={color.border}
      backgroundColor={color.background}
      pfVariant="outlined"
      size="small"
      label={[AccountStatusesLabels[params as AccountStatuses]]}
    />
  );
}
