import { ReactElement, useContext, useEffect, useMemo, useState } from 'react';
import {
  LoadingStateFailedModal,
  UserNotFoundModal,
} from 'src/components/error-modals';
import ProgramsView, { ProgramsViewProps } from 'src/components/programs';
import DegreeAuditContext from 'src/context/degree-audit';
import EnrollmentContext from 'src/context/enrollment';
import { useDegreeAuditQuery } from 'src/hooks/degree-audit';

import { ProgramsLoading } from './programs-loading';

export default function Programs() {
  const degreeAuditQuery = useDegreeAuditQuery();

  const { enrollment, ...enrollmentCtx } = useContext(EnrollmentContext);
  const { degreeAudit, currentTerm } = useContext(DegreeAuditContext);

  const [component, setComponent] = useState<ReactElement>(<></>);

  /** This React state can be removed once we move all these error states to error pages */
  const [errorState, setErrorState] = useState<boolean>(false);
  /** This Error handling is ultimately redundant. If the enrollment call fails the whole app will not load much further up the heirarchy. */
  const [criticalError, setCriticalError] = useState<boolean>(false);

  const queriesStatus = useMemo(() => {
    return [enrollmentCtx.queryStatus, degreeAuditQuery.status];
  }, [enrollmentCtx.queryStatus, degreeAuditQuery.status]);

  useEffect(() => {
    if (!errorState && degreeAudit && currentTerm) {
      if (queriesStatus.includes('loading')) {
        setComponent(<ProgramsLoading />);
      } else if (queriesStatus.includes('error')) {
        setErrorState(true);
      } else if (!enrollment) {
        setCriticalError(true);
      } else {
        const props: ProgramsViewProps = {
          enrollment,
          currentTerm,
          terms: degreeAudit.terms,
          hideGrades: degreeAudit.hideGrades,
        };

        setComponent(<ProgramsView {...props} />);
      }
    }
  }, [queriesStatus, degreeAudit]);

  /** The following useEffect can be removed once we move all these error states to error pages */
  useEffect(() => {
    if (errorState) setComponent(<LoadingStateFailedModal />);
    if (criticalError) setComponent(<UserNotFoundModal />);
  }, [errorState, criticalError]);

  return component;
}
