import { Box, CardActions, CardContent, Divider, Grid, TextField, Typography } from '@mui/material';
import { Alert, Button, Card } from '@pennfoster/pfc-design-system';
import { useState } from 'react';
import { useMutation } from 'react-query';
import { FixUsers } from 'src/api/user';
import { AxiosError } from 'axios';
import { getErrorMessage } from 'src/helpers/handling-errors';
import ReactJson from 'react-json-view';
import { emailChangeAtom, initialEmailChangeState } from 'src/state';
import { useAtom } from 'jotai';
import { EmailChange } from 'src/types/email-change';

export function AdminEmailChange() {
  const [userAccounts, setUserAccounts] = useState<null | { email: string, password: string }[]>(null);
  const [errors, setErrors] = useState<null | string>(null);
  const [emailChange, setEmailChange] = useAtom(emailChangeAtom)
  
  const handleChange = (key: keyof EmailChange, value: string) => {
    setEmailChange((prevState) => ({
      ...prevState,
      [key]: value,
    }))
  }

  const fixUsersMutation = useMutation({
    mutationFn: (data: EmailChange) => FixUsers(data),
    onSuccess: ({ data }) => {
      setUserAccounts(data.userAccounts)
    },
    onError: (error: AxiosError) => {
      const errorMessage = getErrorMessage(error);
      setErrors(errorMessage);
    },
  });

  const handleSubmit = async () => {
    fixUsersMutation.mutate(emailChange)
  };

  const resetForm = async () => {
    setErrors(null)
    setUserAccounts(null)
    setEmailChange(initialEmailChangeState)
  };
  
  return (
    <Box p={3}>
      <Grid container>
          <Card sx={{ pl: 3, pr: 3, pb: 3, mt: 10, maxWidth: 896 }}>
            <Box
              sx={{ pt: 3, pb: 2, pl: 0, pr: 0 }}
              display={'flex'}
              alignItems={'center'}
              justifyContent={'space-between'}
            >
              <Typography component={'span'} variant={'subtitle1'}>
                {' '}
                Email Change{' '}
              </Typography>
            </Box>
            <Divider />
            <CardContent
              sx={{
                pt: 2,
                pb: 0,
                pl: 0,
                pr: 0,
                display: 'flex',
                flexWrap: 'wrap',
                rowGap: 1,
              }}
            >
                {errors && (
                <Alert severity='error' pfVariant='standard'>{errors}</Alert>
              )}
              <Grid container spacing={1}>
                <Grid item xs={12} md={6}>
                  <TextField
                    sx={{ pb: 2, mt: 2 }}
                    fullWidth
                    id="requested-email"
                    label="First Requested Email"
                    variant="outlined"
                    onChange={(e) => handleChange('firstEmail', e.target.value)}
                    value={emailChange.firstEmail}
                  />
                  <TextField
                    fullWidth
                    id="student-number-a"
                    label="First Learner Student Number"
                    variant="outlined"
                    onChange={(e) => handleChange('firstStudentNumber', e.target.value)}
                    value={emailChange.firstStudentNumber}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    sx={{ pb: 2, mt: 2 }}
                    fullWidth
                    id="requested-email"
                    label="Second Requested Email"
                    variant="outlined"
                    onChange={(e) => handleChange('secondEmail', e.target.value)}
                    value={emailChange.secondEmail}
                  />
                  <TextField
                    fullWidth
                    id="student-number-b"
                    label="Second Learner Student Number"
                    variant="outlined"
                    onChange={(e) => handleChange('secondStudentNumber', e.target.value)}
                    value={emailChange.secondStudentNumber}
                  />
                </Grid>
              </Grid>
            </CardContent>
            <CardActions sx={{ display: 'flex', mt: 1, flexWrap: 'wrap' }}>
                <Button pfVariant={'filled'} onClick={resetForm}>
                  Reset Form{' '}
                </Button>
                <Button pfVariant={'filled'} onClick={handleSubmit}>
                  Submit{' '}
                </Button>
            </CardActions>
            {userAccounts && (
                <Box mt={3} rowGap={2} display={'flex'} flexWrap={'wrap'} width={'100%'} flexDirection={'column'}>
                  <Typography variant="subtitle1">Successfully submitted</Typography>
                  <Divider sx={{ width: '100%' }} />
                  {userAccounts.map((user: any, index: number) =>
                    <ReactJson key={index} src={user!} name={`user ${index + 1}`} collapsed={true} />
                  )}
                </Box>
              )}
              
          </Card>
        </Grid>
    </Box>
  );
}
