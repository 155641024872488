import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ElectivesCart, { ElectivesCartProps } from 'src/components/electives/cart';
import ElectivesHeader from 'src/components/electives/header';
import ElectivesSelectionBox, { ElectivesSelectionBoxProps } from 'src/components/electives/selection-box';
import ElectivesSubmissionModal, { ElectivesSubmissionModalProps } from 'src/components/electives/submission-modal';
import AppLayout from 'src/components/layout';
import FeEvents from 'src/events/fe-events';
import { useElectivesMutation } from 'src/hooks/electives';
import { COURSES } from 'src/routes/routemap';
import { SISCourseId } from 'src/types/course';
import { DegreeAuditTerm } from 'src/types/degree-audit-term';
import { ElectivePool, SelectedElective } from 'src/types/electives';
import { User } from 'src/types/user';

import { Box, Grid, useMediaQuery, useTheme } from '@mui/material';

export interface ElectiveViewProps {
  user: User;
  currentTerm: DegreeAuditTerm;
  electivePools: ElectivePool[];
  nNeededElectives: number;
}

export default function ElectivesView({ user, currentTerm, electivePools, nNeededElectives }: ElectiveViewProps) {
  // Hooks
  const theme = useTheme();
  const navigate = useNavigate();

  const electivesMutation = useElectivesMutation(currentTerm);

  const [isSubmitModalOpen, setIsSubmitModalOpen] = useState<boolean>(false);
  const [selectedCourses, setSelectedCourses] = useState<SelectedElective[]>([]);

  // Handlers
  const handleSelectElective = (item: SelectedElective) => {
    FeEvents.trackNamedEvent({
      eventName: 'AddElectiveClickEvent',
      user,
      dataSource: { course: item },
    });
    setSelectedCourses([...selectedCourses, item]);
  };

  const handleRemoveElective = (sisCourseId: SISCourseId) => {
    setSelectedCourses((prev) => {
      return prev.filter((item) => item.sisCourseId !== sisCourseId);
    });
  };

  // Props
  const electivesSelectionBoxProps: ElectivesSelectionBoxProps = {
    hasSelectedMax: selectedCourses.length >= nNeededElectives,
    mutationStatus: electivesMutation.status,
    currentTerm,
    electivePools,
    selectedCourses,
    enrolledElectiveNames: new Set(currentTerm.electives.map((e) => e.name)),
    handleSelectElective,
  };

  const electivesCartProps: ElectivesCartProps = {
    nNeededElectives,
    mutationStatus: electivesMutation.status,
    isSmallScreen: useMediaQuery(theme.breakpoints.down('sm')),
    selectedCourses: selectedCourses,
    handleRemoveElective,
    handleSubmitClick: () => setIsSubmitModalOpen(true),
    handleContinueClick: () => navigate(COURSES),
  };

  const electivesSubmissionModalProps: ElectivesSubmissionModalProps = {
    isOpen: isSubmitModalOpen,
    selectedCourseNames: selectedCourses.map((item) => item.courseName),
    onCancelHandler: () => setIsSubmitModalOpen(false),
    onConfirmHandler: () => {
      if (!selectedCourses.length) {
        throw new Error('No electives selected');
      }
      electivesMutation.mutate(selectedCourses);
      setIsSubmitModalOpen(false);
    },
  };

  // Markup
  return (
    <AppLayout>
      <Box
        sx={{
          flexGrow: 1,
          paddingBottom: '16px',
          [theme.breakpoints.down('sm')]: {
            paddingBottom: 'calc(56px + 16px)',
          },
        }}
      >
        <Grid container spacing={3}>
          <ElectivesHeader />
          <Grid container spacing={3}>
            <ElectivesSelectionBox {...electivesSelectionBoxProps} />
            <ElectivesCart {...electivesCartProps} />
          </Grid>
          <ElectivesSubmissionModal {...electivesSubmissionModalProps} />
        </Grid>
      </Box>
    </AppLayout>
  );
}
