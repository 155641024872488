import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import routemap from 'src/routes/routemap';
import { getEolUrl } from 'src/api/enrollments';
import { Subtitle1 } from 'src/styles';
import { Task } from 'src/types/task';

import { CardContent, CardHeader, Divider, List } from '@mui/material';
import { Card } from '@pennfoster/pfc-design-system';

import { MyTasksCardListItem } from './my-tasks-card-list-item';
import { PathwayStatusValues } from 'src/types/degree-audit-term';
import { cms } from 'src/helpers/language';
import { useElectivesRoutes } from 'src/hooks/electives';
import { Enrollment } from 'src/types/enrollment';

interface Props {
  enrollment: Enrollment;
  pathwayStatus: PathwayStatusValues;
  selectedPathway: boolean | null;
  setIsEligibleToReEnroll: (value: boolean | ((prevVar: boolean) => boolean)) => void;
}

export function MyTasksCard({ pathwayStatus, selectedPathway, enrollment, setIsEligibleToReEnroll }: Props) {
  const [tasks, setTasks] = useState<Task[]>([]);
  const navigate = useNavigate();
  const content = cms.content.pages.dashboard.cards.my_tasks;
  const { electivePathwayRoute } = useElectivesRoutes();
  const navigateUrl = electivePathwayRoute;
  const ENROLL_NEXT_SEMESTER_LABEL = 'Enroll In Next Semester';

  const handleEnrollNextSemester = () => {
    const enrollmentId = enrollment?.enrollmentId;
    setIsEligibleToReEnroll(true);
    getEolUrl(enrollmentId)
      .then((res) => {
        window.location.href = res.data;
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    const updatedTasks: Task[] = [];
    if (pathwayStatus !== PathwayStatusValues.None)
      updatedTasks.push({
        label: `${content.action_word} ${
          pathwayStatus === PathwayStatusValues.Pathway ? content.pathways_label : content.electives_label
        }`,
        action: () => {
          navigate(navigateUrl);
        },
      });

    if (enrollment?.eligibleToReEnroll) {
      updatedTasks.push({
        label: ENROLL_NEXT_SEMESTER_LABEL,
        action: handleEnrollNextSemester,
      });
    }
    setTasks(updatedTasks);
  }, [pathwayStatus, selectedPathway, enrollment]);

  return (
    <Card>
      <CardHeader sx={{ pl: 3, pr: 3, pt: 3 }} title={<Subtitle1 variant="h2">{content.title}</Subtitle1>} />
      <Divider aria-hidden="true" sx={{ ml: 3, mr: 3 }} />
      <CardContent sx={{ pl: 3, pr: 3 }}>
        <List>
          {tasks.map((task, i) => {
            // TODO: Add task.id to key when available.
            return <MyTasksCardListItem key={`task-${i}`} task={task} />;
          })}
        </List>
      </CardContent>
    </Card>
  );
}
