import { AxiosError, AxiosResponse } from 'axios';
import { createContext } from 'react';
import {
  QueryObserverResult,
  QueryStatus,
  RefetchOptions,
  RefetchQueryFilters,
} from 'react-query';
import { noop } from 'src/helpers/noop';
import { Enrollment } from 'src/types/enrollment';

export interface EnrollmentState {
  enrollment?: Enrollment;
  enrollments?: Enrollment[];
  queryStatus: QueryStatus;
  queryError: AxiosError | null;
  refetch: <TPageData>(
    options?: RefetchOptions & RefetchQueryFilters<TPageData>
  ) => Promise<QueryObserverResult<AxiosResponse, AxiosError>>;
}

const EnrollmentContext = createContext<EnrollmentState>({
  queryStatus: 'idle' as QueryStatus,
  queryError: null,
  refetch: noop as EnrollmentState['refetch'],
});

export default EnrollmentContext;
