import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { Card } from '@pennfoster/pfc-design-system';
import ProgressChip from '../../components/progress-chip';
import { ProgressStatus } from 'src/types/progress-status';
import { CardActions, CardContent, Link as MuiLink, Grid, Typography, CircularProgress, useTheme } from '@mui/material';
import StatusTable from '../../components/status-table';
import { Enrollment } from '../../types/enrollment';
import useCourseUrls from '../../hooks/use-course-url';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import DegreeAuditContext from '../../context/degree-audit';
import { Course } from '../../types/course';
import { EmptyState } from '../empty-state';
import EnrollmentContext from 'src/context/enrollment';
import { Subtitle1 } from 'src/styles';
import { LetterGradeCode } from 'src/types/letter-grade-code';
import useCreditPercentage from 'src/hooks/credit-percentage';
import { cms } from 'src/helpers/language';

const UpcomingCoursesCard = ({
  courses,
  enrollment,
  setIsLoadingUrlCourses,
}: {
  courses: Course[];
  enrollment: Enrollment;
  setIsLoadingUrlCourses: (value: boolean | ((prevVar: boolean) => boolean)) => void;
}) => {
  const { enrollmentId } = useContext(EnrollmentContext).enrollment!;
  const [isLoading, setIsLoading] = useState<boolean>(false);
  useCourseUrls.enrollmentId = enrollmentId;
  const { handleCourseRedirect, queryStatus } = useCourseUrls;
  let { currentCourseId } = useCourseUrls;
  const content = cms.content.pages.dashboard.cards.upcoming_courses;

  const theme = useTheme();

  useEffect(() => {
    if (queryStatus === 'loading') {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [queryStatus]);

  const { degreeAudit } = useContext(DegreeAuditContext);

  const hasCourses = courses && courses.length > 0;

  // TODO: We don't have design mockups for the event that no courses are returned.

  const selectedTerm = degreeAudit?.terms.find((t) => t.termId === enrollment?.currentTerm);

  const creditPercentage = useMemo(
    () => useCreditPercentage(enrollment, selectedTerm),
    [enrollment, enrollment.program?.totalNumberTerms, selectedTerm]
  );

  const upcomingCoursesHeader: GridColDef[] = [
    {
      field: 'courseTitle',
      headerName: content.table_title,
      type: 'string',
      flex: 3,
      sortable: false,
      renderCell: (params: GridRenderCellParams) => {
        const course: Course = params.row as Course;
        return (
          <>
            {course.status === ProgressStatus.Locked ||
            !enrollment.learningEnabled ||
            course.letterGrade == LetterGradeCode.Credit ? (
              <Typography>{course.name}</Typography>
            ) : (
              <>
                <MuiLink
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    currentCourseId = course.sisStudentCourseId;
                    handleCourseRedirect(course.sisStudentCourseId, setIsLoadingUrlCourses);
                  }}
                >
                  {course.name}
                </MuiLink>
                {isLoading && course.sisStudentCourseId === currentCourseId && (
                  <CircularProgress sx={{ ml: 1 }} size={'16px'} />
                )}
              </>
            )}
          </>
        );
      },
    },
    {
      field: 'progressStatus',
      cellClassName: 'not-clickable',
      headerName: 'Status',
      type: 'ProgressStatus',
      flex: 1,
      headerAlign: 'right',
      align: 'right',
      sortable: false,
      renderCell: (params: GridRenderCellParams) => {
        return <ProgressChip status={params.row.status} isStatusForLesson={false} />;
      },
    },
  ];

  const rowsWithIds = courses.map((row) => ({
    ...row,
    id: row.sisStudentCourseId,
  }));
  return !hasCourses ? (
    <EmptyState />
  ) : (
    <Card>
      <CardContent sx={{ padding: 3 }}>
        <Grid container>
          <Grid item xs={6}>
            <Subtitle1 variant="h2">{content.title}</Subtitle1>
          </Grid>
          <Grid item xs={6} textAlign="right">
            <Typography component={'span'} variant={'h6'}>{`${creditPercentage}%`}</Typography>
          </Grid>
          <Grid item xs={12}>
            <StatusTable rows={rowsWithIds} header={upcomingCoursesHeader} />
          </Grid>
        </Grid>
        <CardActions
          sx={{
            justifyContent: 'flex-end',
            mt: 2,
            pt: theme.spacing(1),
            pb: theme.spacing(1),
          }}
        >
          <Link to="/courses">{content.view_all_link_label}</Link>
        </CardActions>
      </CardContent>
    </Card>
  );
};

export default UpcomingCoursesCard;
