import { Box, CardActions, CardContent, Divider, Grid, TextField, Typography } from '@mui/material';
import { Button, Card, Icon } from '@pennfoster/pfc-design-system';
import { useContext } from 'react';
import EnrollmentContext from 'src/context/enrollment';
import UserContext from 'src/context/user';
import { useNavigate } from 'react-router-dom';
import routemap from 'src/routes/routemap';
import ReactJson from 'react-json-view';
import LoadingComponent from 'src/components/loading-component';
import { deleteImpersonateSession, postImpersonate } from 'src/api/impersonate';
import { getUserProfile } from 'src/api';
import QueryKeys from 'src/types/query-keys';
import { useQuery } from 'react-query';
import { useAtom } from 'jotai';
import { isImpersonatingAtom, reasonAtom, studentNumberAtom } from 'src/state';

export function AdminImpersonate() {
  const navigate = useNavigate();
    const [studentNumber, setStudentNumber] = useAtom(studentNumberAtom);
    const [isImpersonating, setIsImpersonating] = useAtom(isImpersonatingAtom);
    const [reason, setReason] = useAtom(reasonAtom);
  const { enrollment, queryStatus: enrollmentQueryStatus, refetch: refetchEnrollment } = useContext(EnrollmentContext);
  const { user, queryStatus: userQueryStatus, refetch: refetchUser } = useContext(UserContext);

  const {
    data: profileQueryData,
    status: profileQueryStatus,
    refetch: refetchProfile,
  } = useQuery(QueryKeys.GET_PROFILE, getUserProfile, {
    onError: (error) => console.error('Error: ', error),
    enabled: !isImpersonating,
  });

  const handleImpersonate = async () => {
    postImpersonate(studentNumber, reason)
      .then(() => {
        return Promise.all([refetchUser(), refetchEnrollment(), refetchProfile()]);
      })
      .then(() => {
        setIsImpersonating(true);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  function handleRelease() {
    deleteImpersonateSession()
      .then(() => {
        return Promise.all([refetchUser(), refetchEnrollment()]);
      })
      .then(() => {
        setIsImpersonating(false);
        setStudentNumber('');
        setReason('');
      });
  }

  return (
    <>
      {enrollmentQueryStatus === 'loading' || userQueryStatus === 'loading' ? (
        <LoadingComponent fullPage label={'new student loading'} />
      ) : (
        <Box p={3}>
          <Grid container>
            <Card sx={{ pl: 3, pr: 3, pb: 3, mt: 10, maxWidth: 448 }}>
              <Box
                sx={{ pt: 3, pb: 2, pl: 0, pr: 0 }}
                display={'flex'}
                alignItems={'center'}
                justifyContent={'space-between'}
              >
                <Typography component={'span'} variant={'subtitle1'}>
                  {' '}
                  Impersonate User{' '}
                </Typography>
                <Icon.RemoveRedEye />
              </Box>
              <Divider />
              <CardContent
                sx={{
                  pt: 2,
                  pb: 0,
                  pl: 0,
                  pr: 0,
                  display: 'flex',
                  flexWrap: 'wrap',
                  rowGap: 1,
                }}
              >
                <TextField
                  fullWidth
                  id="student-number"
                  label="Learner Student Number"
                  variant="outlined"
                  onChange={(e) => setStudentNumber(e.target.value)}
                  disabled={isImpersonating}
                  value={studentNumber}
                />
                <TextField
                  fullWidth
                  multiline
                  id="reason"
                  label="Reason"
                  variant="outlined"
                  onChange={(e) => setReason(e.target.value)}
                  disabled={isImpersonating}
                  value={reason}
                />
              </CardContent>
              <CardActions sx={{ display: 'flex', mt: 1, flexWrap: 'wrap' }}>
                <Box width={'100%'} display={'flex'} justifyContent={'end'}>
                  {isImpersonating && (
                    <Button pfVariant={'text'} onClick={handleRelease}>
                      {' '}
                      release{' '}
                    </Button>
                  )}
                  {isImpersonating ? (
                    <Button
                      pfVariant={'filled'}
                      onClick={() => {
                        navigate(routemap.DASHBOARD);
                      }}
                    >
                      {' '}
                      Home{' '}
                    </Button>
                  ) : (
                    <Button pfVariant={'filled'} disabled={isImpersonating} onClick={handleImpersonate}>
                      {' '}
                      Impersonate{' '}
                    </Button>
                  )}
                </Box>
                {isImpersonating && (
                  <Box mt={3} rowGap={2} display={'flex'} flexWrap={'wrap'} width={'100%'}>
                    <Divider sx={{ width: '100%' }} />
                    <ReactJson src={user!} name="currentUser" collapsed={true} />
                    <ReactJson src={enrollment!} name="currentEnrollment" collapsed={true} />
                  </Box>
                )}
              </CardActions>
            </Card>
          </Grid>
        </Box>
      )}
    </>
  );
}
