import { AxiosResponse } from 'axios';
import { Profile, User } from 'src/types/user';

import { instance } from './apiConfig';
import { EmailChange } from 'src/types/email-change';

export function getUser(): Promise<AxiosResponse<User>> {
  return instance({
    method: 'GET',
    url: `/user`,
  });
}

export function getUserProfile(): Promise<AxiosResponse<Profile>> {
  return instance({
    method: 'GET',
    url: `/user/profile`,
  });
}

export function updateUserProfile(data: Profile): Promise<AxiosResponse<Profile>> {
  return instance({
    method: 'PUT',
    url: `/user/profile`,
    data,
  });
}

export function FixUsers(data: EmailChange): Promise<AxiosResponse> {
  return instance({
    method: 'POST',
    url: `/user/fixusers`,
    data,
  });
}
