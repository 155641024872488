import React, { useReducer, useState } from 'react';
import { Box, Radio, RadioGroup } from '@mui/material';
import { useQuery } from 'react-query';
import QueryKeys from 'src/types/query-keys';
import { getOutstandingBalance } from 'src/api/enrollments';
import { ProgramDTO } from 'src/types/enrollment';
import { AxiosResponse } from 'axios';
import { Body1, Subtitle1 } from 'src/styles';
import ChooseProgramSkeleton from './skeleton/choose-program-skeleton';

interface ChooseProgramProps {
  onSelectProgram: (selectedProgram: ProgramDTO) => void;
}

interface SingleProgramProps {
  program: ProgramDTO;
  onClick: (program: ProgramDTO) => void;
}

interface MultipleProgramsProps {
  programs: ProgramDTO[];
  defaultProgramId: number | undefined;
  onClick: (program: ProgramDTO) => void;
}

function SingleProgram({ program, onClick }: SingleProgramProps) {
  return (
    <>
      <Subtitle1 variant="h2">Program</Subtitle1>
      <Box sx={{ pt: 2 }}>
        <Body1>{program.programName}</Body1>
        <RadioGroup
          sx={{ pt: 1 }}
          aria-label="Program"
          value={program.sisEnrollmentId}
        >
          <Radio
            sx={{ display: 'none' }}
            value={program.sisEnrollmentId}
            onClick={() => onClick(program)}
            inputProps={{ 'aria-label': program.programName }}
          />
        </RadioGroup>
      </Box>
    </>
  );
}

function MultiplePrograms({
  programs,
  defaultProgramId,
  onClick,
}: MultipleProgramsProps) {
  return (
    <>
      <Subtitle1 variant="h2">Programs</Subtitle1>
      <RadioGroup
        sx={{ pt: 1 }}
        aria-label="Programs"
        defaultValue={defaultProgramId}
      >
        {programs.map((program) => (
          <Box
            key={program.sisEnrollmentId}
            sx={{
              alignItems: 'start',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
            }}
          >
            <Box
              sx={{
                alignContent: 'center',
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'row',
                width: '100%',
              }}
            >
              <Radio
                checked={defaultProgramId === program.sisEnrollmentId}
                value={program.sisEnrollmentId}
                onClick={() => onClick(program)}
                inputProps={{ 'aria-label': program.programName }}
              />
              <Body1>{program.programName}</Body1>
            </Box>
          </Box>
        ))}
      </RadioGroup>
    </>
  );
}

export default function ChooseProgram({ onSelectProgram }: ChooseProgramProps) {
  const [programsData, setProgramsData] = useState<ProgramDTO[]>([]);
  const [selectedProgramId, setSelectedProgramId] = useState<number>();

  const getProgramsQuery = useQuery(
    [QueryKeys.GET_OUTSTANDING_PROGRAMS],
    async () => await getOutstandingBalance(),
    {
      onSuccess: (resp: AxiosResponse<ProgramDTO[]>) => {
        setProgramsData(resp.data);
        if (
          resp.data.length > 0 &&
          resp.data[0].sisEnrollmentId !== selectedProgramId
        ) {
          setSelectedProgramId(resp.data[0].sisEnrollmentId);
          onSelectProgram(resp.data[0]);
        }
      },
      staleTime: 300000,
      onError: (error) => console.error(error),
    }
  );

  const onClickProgram = async (programDTO: ProgramDTO) => {
    setSelectedProgramId(programDTO.sisEnrollmentId);
    onSelectProgram(programDTO);
  };

  return (
    <>
      {getProgramsQuery.isLoading ? (
        <ChooseProgramSkeleton />
      ) : programsData.length === 1 ? (
        <SingleProgram program={programsData[0]} onClick={onClickProgram} />
      ) : (
        <MultiplePrograms
          programs={programsData}
          defaultProgramId={selectedProgramId}
          onClick={onClickProgram}
        />
      )}
    </>
  );
}
